import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Home() {
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <br></br>
          <h2>Home Page</h2>
          <p>Welcome to the Home Page!</p>
          <Button variant="primary">Learn More</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
